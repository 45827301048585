import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenu,DashbordHeader } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"
import { getAllCategories } from "../../../actions/pages";
import { updateInfoUser } from "../../../actions/user";
import { geocodeByAddress,getLatLng }  from 'react-google-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';


const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { data } = useSelector(state => state.pages)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categ, setCateg] = useState('');
    const [rib, setRib] = useState('');
    const [loading, setLoading] = useState(false);

    const [address,setAddress]=useState("");
    const [address2,setAddress2]=useState("");
    const [address3,setAddress3]=useState("");


    const [value, setValue] = useState(null);
    const [value2, setValue2] = useState(null);
    const [value3, setValue3] = useState(null);





    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
     const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        let go=true;

        if(categories.length <= 0){
            go=false;
            if(currentlang=='fr'){
                alert("Veuillez sélectionner au moins une catégorie !")
            }
            else{
                alert("Please select at least one category!")
            } 
        }



         if(!value || address!=value){
            go=false;
            if(currentlang=='fr'){
                alert("Veuillez choisir une ville !")
            }
            else{
                alert("Please choose a city!")
            } 
        }


        if(go){
           dispatch(updateInfoUser(formDataObj,user.id,'watcher'));
        }
        
    
    }


    useEffect(() => {
    
         dispatch(getAllCategories());
         if(user.catgories && user.catgories.length){

           setCategories(user.catgories)
        
         }

         setAddress2(user?.ville_2);
         setValue2(user?.ville_2);

         setAddress3(user?.ville_3);
         setValue3(user?.ville_3);


         setAddress(user?.ville);
         setValue(user?.ville);

         

       
        
        if(langset == false){
             

            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])

    
     const handleSetCategorie = (categ) => {
            
            let newcategories=[...categories];
            var index = newcategories.indexOf(categ)
            if( index > -1){
               newcategories.splice(index, 1);
               setCategories(newcategories);
 
            }
            else{

               newcategories.push(categ);
               setCategories(newcategories);

            }            
    }


      const createPdf = (file) => {
      
      setLoading(true)
      const data = new FormData();
      data.append('membreId', user.id);
      data.append('file', file);
      data.append('type', 'ribs');
      apiClient().post(`/insertimage`,data)
            .then((res) => {
               setRib(res.data.name)  
               setLoading(false)             
            })

  }

    const uploadPdf = (e) => {
        

    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
    return;

      if(files[0].type=='application/pdf'  ){
              if(files[0].size <= (2*1000000)){
                 createPdf(files[0]);
              }
              else{

                 alert(t('Votre pièce-jointe ne doit pas dépasser cette taille: 2 MO.'));
              }
      }
      else{
         alert(t("Ce format n'est pas supporté ! Le format accepté est (PDF)"));
      }

        const param = {
                id:user.id,
              //files_img:this.state.files,
            }
    }


         const handleChange = address => {
     
        setAddress(address);
      };

      const handleChange2 = address => {
     
        setAddress2(address);
      };

      const handleChange3 = address => {
     
        setAddress3(address);
      };
     const selectValuemap=(value)=>{
        
        setAddress(value);
        setValue(value);
        geocodeByAddress(value)
         .then(results => getLatLng(results[0]))
         .then(({ lat, lng }) =>{
           
         }
         );

     }


     const selectValuemap2=(value)=>{
        
        setAddress2(value);
        setValue2(value);
        geocodeByAddress(value)
         .then(results => getLatLng(results[0]))
         .then(({ lat, lng }) =>{
            
         }
         );

     }

 const selectValuemap3=(value)=>{
        
        setAddress3(value);
        setValue3(value);
        geocodeByAddress(value)
         .then(results => getLatLng(results[0]))
         .then(({ lat, lng }) =>{
            
         }
         );

     }


 const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions,loading }) => (
        
        <div className="autocomplete-container autocomplete-root">
                                                   
             
                 <input required   defaultValue={user?.ville}  name="ville" {...getInputProps({
                          placeholder: t('Ville'),
                          className: 'form-control utocomplete-input',
                      })} />
                 

        <div className="autocomplete-dropdown-container my-2">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion,index) => (
            
              <div key={index}  className="listitem" {...getSuggestionItemProps(suggestion)}>
                <span >{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>

      
      );



 const renderFunc2 = ({ getInputProps, getSuggestionItemProps, suggestions,loading }) => (
        
        <div className="autocomplete-container autocomplete-root">
                                                   
             
                 <input   defaultValue={user?.ville_2} name="ville2" {...getInputProps({
                          placeholder: t('Ville'),
                          className: 'form-control utocomplete-input',
                      })} />
                 

        <div className="autocomplete-dropdown-container my-2">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion,index) => (
            
              <div key={index}  className="listitem" {...getSuggestionItemProps(suggestion)}>
                <span >{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>

      
      );


 const renderFunc3 = ({ getInputProps, getSuggestionItemProps, suggestions,loading }) => (
        
        <div className="autocomplete-container autocomplete-root">
                                                   
             
                 <input  name="ville3"   defaultValue={user?.ville_3} {...getInputProps({
                          placeholder: t('Ville'),
                          className: 'form-control utocomplete-input',
                      })} />
                 

        <div className="autocomplete-dropdown-container my-2">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion,index) => (
            
              <div key={index}  className="listitem" {...getSuggestionItemProps(suggestion)}>
                <span >{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>

      
      );


    return (
        <div className="myccount editprofil " >
            
            <DashbordHeader />

            <section className="gray-bg main-dashboard-sec dashboard" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenu src='editprofil'/>
                                 
                            </div>


                            <div className='col-md-9'>

                                <div className='tab' id=''>
                                 <div className="dashboard-title   fl-wrap">
                                    <h3 className='htri1'>{t('Modifier mon profile')} </h3>
                                    <br/><br/>

                                    {
                                        !user.rib &&
                                         <p class="para alert alert-warning center">{t('Veuillez enregistrer vos informations de paiement.')}</p>
                                    }

                                   

                              <form  onSubmit={handleSubmit} >

                              <div className="profile-edit-container fl-wrap block_box" style={{marginBottom:"0px"}}>
                                    <div className="custom-form">
                                        <div className="row">

                                          <div className='col-md-6'>

                                            <label  for="imgprofil" className="color-bg edit-prof_btn labbnrd">

                                              <input
                                                type="file"
                                                name="imgprofil"
                                                className='inputFileInvis'
                                                onChange={(event) => {
                                                  
                                                  uploadPdf(event);
                                                }}
                                              />
                                              {
                                                rib !='' ? 'RIB.pdf' 
                                                :
                                                t('Télécharger votre RIB..')
                                              }

                                                

                                            </label>
                                          </div>
                                          <div className='col-md-6'>

                                          {
                                            user.rib &&
                                            <a href={"https://mywatcher.fr/admin/public/img/ribs/"+user.id+"/"+user.rib } download >RIB.PDF</a>
                                          }

                                          { 
                                            loading &&  <img src='/images/loading.gif' />
                                          }
 
                                           
                                          </div>

                                        </div>
                                    </div>
                              </div>

                               <div className="row" style={{marginBottom:'10px'}}>
                                            <div className="col-sm-12">
                                                 <button type="submit" className="btn float-btn color2-bg btn9876 "> {t('Modifier')} <i className="fas fa-caret-right"></i></button>
                                            </div>
                                        </div>

                          <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <input type='hidden' value={rib} name='rib'  />
                                                <label>{t('Prénom')}* <i className="fal fa-user"></i></label>
                                                <input placeholder="Robert" name="firstname" type="text" className="form-control"  defaultValue={user.prenom} required/>                                              
                                            </div>
                                            <div className="col-sm-6">
                                                <label>{t('Nom')}* <i className="fal fa-user"></i></label>
                                                 <input placeholder="Plafond" name="lastname" type="text" className="form-control" defaultValue={user.nom} required/>                                              
                                            </div>
                                           
                                            <div className="col-sm-6">
                                                <label>{t('Téléphone')}*<i className="far fa-phone"></i>  </label>
                                                <input type="text" placeholder="+7(123)987654" name="tel"  defaultValue={user.telephone} required  />                                                
                                            </div>
                                           
                                             <div className="col-sm-6">
                                                <label>{t('Email')}*<i className="far fa-envelope"></i>  </label>
                                                <input type="text" placeholder="JessieManrty@domain.com" name="email" required  defaultValue={user.email} />                                                
                                            </div>
                                            <div className="col-sm-6">
                                                <label> {t('Mot de passe')}* <i className="far fa-globe"></i>  </label>
                                                <input type="text" placeholder="********" name='password' />                                                
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>



                            <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label>{t('Décris toi brièvement (ce que tu aimes, tes passions, etc.) max 150 mots pour tes futurs contacts')}* </label>
                                                <textarea cols="40" rows="3" placeholder="About Me" name='description' className='form-control' required>{user.description}</textarea>                                             
                                            </div>
                                        </div>
                                      
                                    </div>
                             </div>


                             <div className="profile-edit-container fl-wrap block_box devenirassistant ">
                                    <div className="custom-form">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label>{t('Vous pouvez assister')} * </label>   
                                            </div>

                                    {data.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                            
                                        
                                             <div key={index} className='col-md-3' >
                                                <div  className={'geodir_status_date  tain'+newindex}  onClick={()=>handleSetCategorie(item.id)}> {currentlang == 'fr' ? item.label : item.label_en } {categ} 
                                                 {
                                                   categories.includes(item.id) ? <i class="fal fa-check"></i> : null
                                                 }
                                                </div>
                                             </div>

                                        );
                            
                  })}

 

                                        </div>
                                      
                                    </div>
                             </div>

                             <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label>{t('Votre tarification minimum')}* <i className="fas fa-euro-sign"></i>  </label>

                                                 <input type="number" style={{paddingLeft:'50px'}} placeholder="€110/hr" className="form-control" name='price' required  defaultValue={user.tarif} /> 
                                                                   
                                            </div>
                                        </div>
                                      
                                    </div>
                             </div>



                             <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">
                                         <div className="mb-3 row">
              <div className="col">
              <label className="">{t('Ville')} 1 {t('(you can check)')}*</label>

                  <PlacesAutocomplete  value={address} onChange={handleChange}   onSelect={selectValuemap} searchOptions={{ types: ['(cities)'] }}>
                      {renderFunc}
                  </PlacesAutocomplete>
                
              </div>
            </div>

            <div className="mb-3 row">
              <div className="col">
              <label className="">{t('Ville')} 2 {t('(you can check)')}</label>

                  <PlacesAutocomplete  value={address2} onChange={handleChange2}   onSelect={selectValuemap2} searchOptions={{ types: ['(cities)'] }}>
                      {renderFunc2}
                  </PlacesAutocomplete>
                
              </div>
            </div>

            <div className="mb-3 row">
              <div className="col">
              <label className="">{t('Ville')} 3 {t('(you can check)')}</label>

                  <PlacesAutocomplete  value={address3} onChange={handleChange3}   onSelect={selectValuemap3} searchOptions={{ types: ['(cities)'] }}>
                      {renderFunc3}
                  </PlacesAutocomplete>
                
              </div>
            </div>
                                      
                                    </div>
                             </div>





 
                                        <div className="row">
                                            <div className="col-sm-12">
                                             <input type='hidden' value={categories} name='categories'/>
                                                 <button type="submit" className="btn float-btn color2-bg btn9876 "> {t('Modifier')} <i className="fas fa-caret-right"></i></button>
                                            </div>
                                        </div>

                                        <p>* {t('champs obligatoires')}</p>
                                      
                             








                                   </form>

                                 
                                 </div>
                                </div>

                            </div>
                    </div>
                    
            </section>

        </div>
    );
}

export default Page;