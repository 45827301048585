import React, { useEffect,useState } from "react";
import './styles.scss'; 
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { useParams } from "react-router";
import { apiClient } from "../../actions/api"
import Slider from '@material-ui/core/Slider';
import { useTranslation } from 'react-i18next';
import { saveMission } from "../../actions/pages";
import { registerUser,loginUser,loginUserFacebook,loginUserMission,registerUserMission } from "../../actions/user";

import FacebookLogin from 'react-facebook-login';


const Page = () => { 
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const [wait, setWait] = useState(false);
    const handleClose = () => setShow(false);

 
    const { user,watchersList } = useSelector(state => state.user)

    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);

    const categoryitem = localStorage.getItem("categoryitem");
    const villeitem = localStorage.getItem("villeitem");

    const formDataObj_mission = JSON.parse(localStorage.getItem("formDataObj_mission"));
    const price_mission = localStorage.getItem("price_mission");
    const categoryitem_mission = localStorage.getItem("categoryitem_mission");
    const villeitem_mission = localStorage.getItem("villeitem_mission");
   

   const handleGoTo = (e) => {
        e.preventDefault();
        window.location.href='/mon-compte'
    }
 
 
   
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        //dispatch(registerUserMission(formDataObj,'user',currentlang,formDataObj_mission,price_mission,categoryitem_mission,villeitem_mission));
    
      setWait(true)

            const data = {
        ...formDataObj,
        token:'A',
        referer:'none',
        origin_id:2,
        profile:'user',
        currentlang:currentlang
    }
    return apiClient().post(`/membre/create`, data)
        .then((res) => {
            
            if(res.data.success==false){
                alert(res.data?.message)
            }else{

              
                // save mission 

                const data = {
                    ...formDataObj_mission,
                    token:'',
                    referer:'none',
                    origin_id:2,
                    price:price_mission,
                    categorie:categoryitem_mission,
                    ville:villeitem_mission,
                    user:res.data?.user.id,
                    currentlang:currentlang
                }
                let newuser= res.data?.user 
                return apiClient().post(`/mission/save`, data)
                    .then((res) => {
                       
                        if(res.data.success==false){
                             alert(res.data?.message)
                             window.location.reload();
                        }else{
                                // remove storage
                                localStorage.setItem("price_mission","");
                                setShow(true)
                                setTimeout(function(){
                                  dispatch(loginUserMission(newuser));
                                   window.location.href='/my-account/';
                             }, 2000);



                                
                          
                        }
                    })

              
            }
        })





    }


      const handlerconnexion = async (e) =>{
         e.preventDefault();
         const formData = new FormData(e.target)
         const formDataObj = Object.fromEntries(formData.entries())
       
        
       setWait(true)

        const data = {
        ...formDataObj,
        profile:'user',
        currentlang:currentlang
    }
    return apiClient().post(`/membre/login`, data)
        .then((res) => {
            
            if(res.data.success==false){
                alert(res.data?.message)
            }else{
                 

                // save mission 

                const data = {
                    ...formDataObj_mission,
                    token:'',
                    referer:'none',
                    origin_id:2,
                    price:price_mission,
                    categorie:categoryitem_mission,
                    ville:villeitem_mission,
                    user:res.data?.user.id,
                    currentlang:currentlang
                }
                let newuser= res.data?.user 
                return apiClient().post(`/mission/save`, data)
                    .then((res) => {
                       
                        if(res.data.success==false){
                             alert(res.data?.message)
                             window.location.reload();
                        }else{
                                // remove storage
                                localStorage.setItem("price_mission","");
                                setShow(true)
                                setTimeout(function(){
                                  dispatch(loginUserMission(newuser));
                                   window.location.href='/my-account/';
                             }, 2000);



                                
                          
                        }
                    })
                
            }
        })



    }


    const componentClicked =  () => {
        
       
    }




     const responseFacebook = (response) => {

    const membreData = {
        email: response.email,
    }

    return apiClient().post(`/check/user`, membreData)
          .then((res) =>
           { 

            if(res.data.success==true){
               
                 dispatch(loginUserFacebook(res))
            }
            else{

                 const params = {
                  email: response.email,
                  fname: response.first_name,
                  lname: response.last_name,
                  tel:'',
                  password:'EY778UEIEU76567'
                 }

                  dispatch(registerUser(params,'user',currentlang));

            }

         })

 
       
} 
 

     useEffect(() => {


        if(!price_mission || price_mission=='' || !user){
              window.location.href='/'
        }
        if(langset == false){


            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 
 

    }, [])
   
    return (
        <div className="login listing" >
           <section className="gray-bg small-padding no-top-padding-sec" id="sec1">
                <div className="container">
                    <div className="fl-wrap">
                        <div className="row">


            <div className="col-md-4">
              
                <div className="main-register-holder tabs-act">
                    <div className="main-register fl-wrap  ">
                        <div className="main-register_title">
{t('Créez ou accédez à votre compte pour valider votre mission')}
                        </div>
                  
                        <ul className="tabs-menu fl-wrap no-list-style">
                            <li className="current" ><a href="#tab-2"><i className="fal fa-user-plus"></i> {t('Inscription')}</a></li>
                            <li ><a href="#tab-1"><i className="fal fa-sign-in-alt"></i> {t('Connexion')}</a></li>
                            
                        </ul>
                                              
                        <div className="tabs-container">
                            <div className="tab">
                                
                                <div id="tab-1" className="tab-content ">
                                    <div className="custom-form">
                                        <form  name="registerform" onSubmit={handlerconnexion}  >
                                            <label>{t('Email')} <span>*</span> </label>
                                            <input name="email" type="email" required/>
                                            <label >{t('Mot de passe')} <span>*</span> </label>
                                            <input name="password" type="password" required   / >
                                            <button type="submit"  className="btn float-btn color2-bg " > { wait== false ? t('Connexion') : t('....')} <i className="fas fa-caret-right"></i></button>
                                            <div className="clearfix"></div>
                                            <div className="filter-tags">
                                                <input id="check-a3" type="checkbox" name="check"  />
                                                <label for="check-a3">{t('Se souvenir de moi')}</label>
                                            </div>
                                        </form>
                                        <div className="lost_password">
                                            <a  href="/password/user">{t('Mot de passe oublié ?')}</a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="tab">
                                    <div id="tab-2" className="tab-content first-tab">
                                        <div className="custom-form">
                                            <form  onSubmit={handleSubmit}  name="registerform" className="main-register-form" id="main-register-form2">
                                                
                                                <label >{t('Prénom')} <span>*</span> </label>
                                                <input name="fname" type="text"   required />
                                                <label >{t('Nom')} <span>*</span> </label>
                                                <input name="lname" type="text"    required />
                                                <label >{t('Téléphone')} <span>*</span> </label>
                                                <input name="tel" type="text"   required />

                                                <label>{t('Email')}  <span>*</span></label>
                                                <input name="email" type="email"  required/>
                                                <label >{t('Mot de passe')} <span>*</span></label>
                                                <input name="password" type="password"  required />
                                                <div className="filter-tags ft-list"> 
                                                    <input id="check-a2" type="checkbox" name="check" required />
                                                    <label for="check-a2">{t('En créant un compte, j’accepte les')} <a href="javascript:void(0)">{t('CGU')}</a></label>
                                                </div>
                                                <div className="clearfix"></div>
                                               
                                                <div className="clearfix"></div>
                                                <button type="submit"      className="btn float-btn color2-bg"> {wait== false ? t('Je créée mon compte') : t('....')}  <i className="fas fa-caret-right"></i></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                   
                            <div className="wave-bg">
                                <div className='wave -one'></div>
                                <div className='wave -two'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-md-8'>


                                                       <div className="list-main-wrap-header fl-wrap block_box no-vis-shadow">
                                            <div className="list-main-wrap-title">
                                                <h2 className='mrg556'>{t('Bonne nouvelle votre watcher est actif sur cette ville')} - {villeitem} </h2>
                                            </div>
                                </div>


                                <div className="grid-item-holder gallery-items fl-wrap" style={{marginTop:'20px'}}>
                               
                                    <div className="listing-item row">

                                    {
                                      watchersList.length <=0 &&

                                      <div>
                                           <div className='alert alert-warning'>{t('Nous sommes en cours d’installation dans cette ville renseignez votre email nous vous préviendrons.')}</div>
                                             

                                             <div>
 


                                             </div>

                                      </div>

                                      
                                    }

                                     {watchersList.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                             <AssistantItem key={index} ln={currentlang} item={item} src={'l'} />
                                        );
                            
                                     })}
                                         
                                         

                                    </div>
                                

                                </div>


            </div>




                        </div>

                     </div>
                 </div>

           </section>

<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>

          <div className='center'>
            <img src='/images/send.png' />
          </div>

          <br/><br/>
            <div className='center'><h4 className='center'>{t('Félicitations')} </h4></div>
           <br/>
          <div className='center'>
             {t('Nous avons envoyé votre demande aux assistants selectionés, nous vous informerons de leur disponibilité pour votre mission.')}
          </div>

        </Modal.Body>
        <Modal.Footer>
          
          <Button variant="success" onClick={handleGoTo}>
            {t('Dashboard')} 
          </Button>
        </Modal.Footer>
</Modal>
             
        </div>
    );
}

export default Page;