import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenu,DashbordHeader } from "../../../components";
import { getListMissions,EditMission,getListValidateMissions,getListRealiseMissions,getListEncoursMissions,setMessages } from "../../../actions/pages";
import { useTranslation } from 'react-i18next';

const Page = () => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const { user } = useSelector(state => state.user)
    const { missions,missionsV,missionsE,missionsR } = useSelector(state => state.pages)
    const [langset, setLangset] = useState(false);
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const [show, setShow] = useState(false);
     const [photo, setPhoto] = useState('');
    const [lname, setLname] = useState('');
    const [to, setTo] = useState('');
    const handleClose = () => setShow(false);
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(to){


        const formData = new FormData(e.target)
          const formDataObj = Object.fromEntries(formData.entries())
       
        dispatch(setMessages(formDataObj,user.id,to,'watcher','save',user.emailToken,t('Votre message est bien été envoyé..')));
        setShow(false)
        setPhoto('')
        setLname('')
        setTo('')

        }

        
      
    }

    const handleSetProfil =  (profil) => {
       

        setPhoto(profil.photo)
        setLname(profil.prenom)
        setTo(profil.id)
        setShow(true)
    }


      useEffect(() => {
        const params={
           id:user.id,
           type:'recus',
           profile:'watcher'
        }

        dispatch(getListMissions(params));
        const params2={
           id:user.id,
           type:'enattente',
           profile:'watcher'
        }
        dispatch(getListValidateMissions(params2));

        const params3={
           id:user.id,
           type:'encours',
           profile:'watcher'
        }
        dispatch(getListEncoursMissions(params3));


        const params4={
           id:user.id,
           type:'complete',
           profile:'watcher'
        }
        dispatch(getListRealiseMissions(params4));
        
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])
    
 
    return (
        <div className="myccount responses " >
            
            <DashbordHeader src={'w'} />

            <section className="gray-bg main-dashboard-sec dashboard myccount" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenu src='profil'/>
                                 
                            </div>


                         <div className='col-md-9'>

                             <div className='tab' id=''>
                                 <div class="dashboard-title   fl-wrap">
                                 { !user.rib &&
                                    <h3 className='htri1'>Dashboard </h3>  }
                                    { !user.rib &&
                                    <div><br/><br/></div>
                                  }


                                   { !user.rib &&

                                    <div className='row brd89'>

                                        <div className='col-md-8 col-sm-12'>
                                           <div className="progress ">
                                              <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style={{width:'90%'}}>
                                                <span className="sr-only">90% </span>
                                              </div>
                                            </div>
                                            
                                              
                                             <p className='para alert alert-warning center'>{t('Complétez l’insertion et la saisie de toutes vos données pour activer votre compte.')}</p>
                                           
                                        </div>
                                        <div className='col-md-4 col-sm-12' style={{height:"70px"}}>
                                            <a href='/assistants/modifier-profile/'  className="add-list color-bg">{t('Compléter mon profil')} <span><i className="fal fa-layer-plus"></i></span></a>
                                        </div>

                                    </div>

                                  }

                                     <br/><br/>

                                    <h3 className='htri1'>{t('Missions reçues')}</h3>
                                     <table  id="datatable_encours" className="table table-separate datatable-client table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                    <thead>
                                    <tr>
                                        <th>{t('Ref')}#</th>
                                        <th>{t('Client')}</th>
                                        <th>{t('Localisation')}</th>
                                        <th>{t('Date')}</th>
                                        <th>{t('Action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                    

                                       {missions.map((item,index) => {
                                      
                                       return (
                                      <tr>
                                      <td><span style={{display:'none'}}>{item.date_mission_order}</span> {item.ref}</td>
                                      <td><td><img src={item.user && item.user.photo} className='prfimg' /> {item.user && item.user.prenom} {item.user && item.user.nom}</td></td>
                                      <td>{item.adresse}, {item.ville} </td>
                                      <td>{item.date_mission}</td>
                                      <td>
                                          
                                       <div className="list-contr">
                                                
                                                <a href={"/watchers/detail/mission/"+item.id} className="color-bg tolt center" data-microtip-position="left" data-tooltip={t('Voir détail')}><i class="fal fa-eye"></i></a>
                     
                                                <a href="javascript:void(0)" onClick={()=>handleSetProfil(item.user)} className="red-bg tolt center" data-microtip-position="left" data-tooltip={t('Messages')}><i class="fal fa-envelope"></i></a>
                                                
                                        </div>


                                      </td>
                                      </tr>
                                        );
                            
                                     })}
             
                                  
                                    </tbody>
                            </table>


                                    <br/><br/>

                                    <h3 className='htri1'>{t('Missions en attente de paiement')}</h3>
                                     <table  id="datatable_encours" className="table table-separate datatable-client table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                    <thead>
                                    <tr>
                                       <th>{t('Ref')}#</th>
                                        <th>{t('Client')}</th>
                                        <th>{t('Localisation')}</th>
                                        <th>{t('Date')}</th>
                                        <th>{t('Action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                       

                                    {missionsV.map((item,index) => {
                                      
                                       return (
                                      <tr>
                                      <td>{item.ref}</td>
                                      <td><td><img src={item.user && item.user.photo} className='prfimg' /> {item.user && item.user.prenom} {item.user && item.user.nom}</td></td>
                                      <td>{item.adresse}, {item.ville} </td>
                                      <td>{item.date_mission}</td>
                                      <td>
                                          
                                       <div className="list-contr">
                                                
                                                <a href={"/watchers/detail/mission/"+item.id} className="color-bg tolt center" data-microtip-position="left" data-tooltip={t('Voir détail')}><i class="fal fa-eye"></i></a>
                     
                                                <a href="javascript:void(0)" onClick={()=>handleSetProfil(item.user)} className="red-bg tolt center" data-microtip-position="left" data-tooltip={t('Messages')}><i class="fal fa-envelope"></i></a>
                                                
                                        </div>


                                      </td>
                                      </tr>
                                        );
                            
                                     })}
             
                                  
                                    </tbody>
                            </table>



                                    <br/><br/>

                                    <h3 className='htri1'>{t('Missions en cours watcher')}</h3>
                                     <table  id="datatable_encours" className="table table-separate datatable-client table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                    <thead>
                                    <tr>
                                        <th>{t('Ref')}#</th>
                                        <th>{t('Client')}</th>
                                        <th>{t('Localisation')}</th>
                                        <th>{t('Date')}</th>
                                        <th>{t('Action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                  {missionsE.map((item,index) => {
                                      
                                       return (
                                      <tr>
                                      <td>{item.ref}</td>
                                      <td><td><img src={item.user && item.user.photo} className='prfimg' /> {item.user && item.user.prenom} {item.user && item.user.nom}</td></td>
                                      <td>{item.adresse}, {item.ville} </td>
                                      <td>{item.date_mission}</td>
                                      <td>
                                          
                                       <div className="list-contr">
                                                
                                                <a href={"/watchers/detail/mission/"+item.id} className="color-bg tolt center" data-microtip-position="left" data-tooltip={t('Voir détail')}><i class="fal fa-eye"></i></a>
                     
                                                <a href="javascript:void(0)" onClick={()=>handleSetProfil(item.user)} className="red-bg tolt center" data-microtip-position="left" data-tooltip={t('Messages')}><i class="fal fa-envelope"></i></a>
                                                
                                        </div>


                                      </td>
                                      </tr>
                                        );
                            
                                     })}
                                      
             
                                  
                                    </tbody>
                            </table>


                                <br/><br/>

                                    <h3 className='htri1'>{t('Missions réalisées')}</h3>
                                     <table  id="datatable_encours" className="table table-separate datatable-client table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                    <thead>
                                    <tr>
                                        <th>{t('Ref')}#</th>
                                        <th>{t('Client')}</th>
                                        <th>{t('Localisation')}</th>
                                        <th>{t('Date')}</th>
                                        <th>{t('Action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        
                                    {missionsR.map((item,index) => {
                                      
                                       return (
                                      <tr>
                                      <td>{item.ref}</td>
                                      <td><td><img src={item.user && item.user.photo} className='prfimg' /> {item.user && item.user.prenom} {item.user && item.user.nom}</td></td>
                                      <td>{item.adresse}, {item.ville} </td>
                                      <td>{item.date_mission}</td>
                                      <td>
                                          
                                       <div className="list-contr">
                                                
                                                <a href={"/watchers/detail/mission/"+item.id} className="color-bg tolt center" data-microtip-position="left" data-tooltip={t('Voir détail')}><i class="fal fa-eye"></i></a>
                     
                                                <a href="javascript:void(0)" onClick={()=>handleSetProfil(item.user)} className="red-bg tolt center" data-microtip-position="left" data-tooltip={t('Messages')}><i class="fal fa-envelope"></i></a>
                                                
                                        </div>


                                      </td>
                                      </tr>
                                        );
                            
                                     })}
             
                                  
                                    </tbody>
                            </table>





                                 </div>
                              </div>
 

                              

                         </div>
                    </div>
            </section>
<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>

          <div className='center'>
             <img src={photo} className='msgprofile' />
             
             <div>{lname}</div>
          </div>

          <br/>

          <form onSubmit={handleSubmit}>

                 <div >
                                        <div className="row">
                                            <div className="col-sm-12">
                                                 
                                                <textarea style={{height: "100px"}} cols="40" rows="2" placeholder={t('Votre message')+'...'} name='message' className='form-control' required></textarea>                                             
                                            </div>
                                        </div>
                                 <div className="row">
                                            <div className="col-sm-12">
                                            
                                                 <button type="submit" className="btn float-btn color2-bg btn9876 "> {t('Envoyer')} <i className="fas fa-caret-right"></i></button>
                                            </div>
                               </div>     
                   </div>

          </form>
         
           

        </Modal.Body>
        <Modal.Footer>
          
         
        </Modal.Footer>
</Modal>

        </div>
    );
}

export default Page;