import React,{ useState,useEffect } from "react";
import './styles.scss';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { IoMdTime } from 'react-icons/io';
import { BiCategoryAlt } from 'react-icons/bi';
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../actions/api"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { updateInfoUser } from "../../actions/user";
const Component = ({ item }) => {
    const history = useHistory();
    const { user } = useSelector(state => state.user)
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);

    useEffect(() => {
      
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 


    }, [])
     

  const createImage = (file) => {
   
      const data = new FormData();
      data.append('membreId', user.id);
      data.append('file', file);

      if(user.type==1){
         data.append('type', 'pieces_membre');
      }
      else{
        data.append('type', 'pieces_watcher');
      }
      
      apiClient().post(`/insertimage`,data)
            .then((res) => {
               console.log(res)
                 
                const params = {
                photo: res.data.name,
                 
                }
                dispatch(updateInfoUser(params,user.id,user.type==1 ? 'user' : 'watcher' ))
               
            })

  }

    const changeProfilPicture = (e) => {
        

    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
    return;

      if(files[0].type=='image/png' || files[0].type=='image/jpeg' || files[0].type=='image/jpg' || files[0].type=='image/gif' ){
              if(files[0].size <= (2*1000000)){
                 createImage(files[0]);
              }
              else{

                 alert(t('Votre pièce-jointe ne doit pas dépasser cette taille: 2 MO.'));
              }
      }
      else{
         alert(t("Ce format n'est pas supporté ! Les formats acceptés sont (JPG,JPEG,PNG,GIF)"));
      }

        const param = {
                id:user.id,
              //files_img:this.state.files,
            }
    }

 
    return (

      <section className="parallax-section dashboard-header-sec gradient-bg menudashbord" data-scrollax-parent="true">
              <div className="container">
                   <div className="dashboard-header_conatiner fl-wrap dashboard-header_title">
                                <h1>{t('Bienvenue')} : <span> {user.prenom} {user.nom}</span></h1>
                    </div>

                    <div className="clearfix"></div>
                    <div className="dashboard-header fl-wrap">
                            <div className="container">
                                 <div className="dashboard-header_conatiner fl-wrap">
                                    <div className="dashboard-header-avatar">
                                        <img src={user.profil_picture ? user.profil_picture : "https://mywatcher.fr/admin/public/img/avatar-default.png"} alt="" />
                                        

                                        <label  for="imgprofil" className="color-bg edit-prof_btn">

                                      <input
                                        type="file"
                                        name="imgprofil"
                                        className='inputFileInvis'
                                        onChange={(event) => {
                                          
                                          changeProfilPicture(event);
                                        }}
                                      />

                                        <i className="fal fa-edit"></i>

                                        </label>

 
     

                                    </div>
                                </div>
                            </div>
                    </div>

              </div>
    </section>
    );
}

export default Component;